import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo/Seo"
import Hero from "../components/Hero/Hero"
import CtaWithImage from "../components/CtaWithImage/CtaWithImage"
import Testimonial from "../components/Testimonial/Testimonial"
import SimpleCta from "../components/SimpleCta/SimpleCta"
import HomeBlogContainer from "../components/HomeBlogContainer/HomeBlogContainer"

const IndexPage = ({ data: { wpPage } }) => {
  const posts = wpPage.featuredPosts.posts

  return (
    <>
      <Seo title="Energy Research Group" />
      <Hero
        heroText={wpPage.heroBanner.heroText}
        heroSubText={wpPage.heroBanner.heroSubtext}
        heroParagraphText={wpPage.heroBanner.heroParagraphText}
        primaryButtonText={wpPage.heroBanner.primaryButtonText}
        primaryButtonSlug={wpPage.heroBanner.primaryButtonSlug}
        secondaryButtonText={wpPage.heroBanner.secondaryButtonText}
        secondaryButtonSlug={wpPage.heroBanner.secondaryButtonSlug}
        image={wpPage.heroBanner.heroImage.localFile}
        altText={wpPage.heroBanner.heroImage.altText}
      />
      <SimpleCta
        text={wpPage.simpleCallToAction.ctaHeadingText}
        subText={wpPage.simpleCallToAction.ctaSubheadingText}
        buttonText={wpPage.simpleCallToAction.ctaButtonText}
        buttonSlug={wpPage.simpleCallToAction.ctaButtonSlug}
      />
      <CtaWithImage
        headingText={wpPage.ctaWithImage.headingText}
        paragraphText={wpPage.ctaWithImage.paragraphText}
        buttonText={wpPage.ctaWithImage.buttonText}
        buttonSlug={wpPage.ctaWithImage.buttonSlug}
        image={wpPage.ctaWithImage.ctaImage.localFile}
        altText={wpPage.ctaWithImage.ctaImage.altText}
      />
      <Testimonial
        text={wpPage.testimonial.testimonialText}
        author={wpPage.testimonial.testimonialAuthor}
        affiliation={wpPage.testimonial.testimonialAffiliation}
      />
      <HomeBlogContainer posts={posts} />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      uri
      title
      heroBanner {
        heroText
        heroSubtext
        heroParagraphText
        primaryButtonText
        primaryButtonSlug
        secondaryButtonText
        secondaryButtonSlug
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1400, quality: 100)
            }
          }
        }
      }
      ctaWithImage {
        headingText
        paragraphText
        buttonText
        buttonSlug
        ctaImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1400, quality: 100)
            }
          }
        }
      }
      testimonial {
        testimonialText
        testimonialAuthor
        testimonialAffiliation
      }
      simpleCallToAction {
        ctaHeadingText
        ctaSubheadingText
        ctaButtonText
        ctaButtonSlug
      }
      featuredPosts {
        posts {
          featuredHeading
          featuredParagraphText
          featuredUrl
          featuredPostImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
          featuredPostCategory
        }
      }
    }
  }
`
