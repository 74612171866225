import * as React from "react"
import { ShoppingCartIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const Hero = props => {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div className="mt-0 pt-12 mx-auto max-w-7xl md: md:pt-20 px-4 sm:px-6 lg:px-8">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">{props.heroText}</span>{" "}
                <span className="block text-primaryDarker xl:inline">
                  {props.heroSubText}
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {props.heroParagraphText}
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <Link
                    to={props.primaryButtonSlug}
                    className="w-full flex items-center justify-center px-8 py-3 border-2 border-transparent text-lg font-bold rounded-md text-white bg-secondaryDarker hover:bg-secondary md:py-3 md:text-xl md:px-10"
                  >
                    {props.primaryButtonText}{" "}
                    <ShoppingCartIcon className=" ml-2 h-6 w-6 text-white" />
                  </Link>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <Link
                    to={props.secondaryButtonSlug}
                    className="w-full flex items-center justify-center px-8 py-3 border-2 border-primary text-lg font-bold rounded-md text-primaryDarker hover:bg-primary hover:text-white md:py-3 md:text-xl md:px-10"
                  >
                    {props.secondaryButtonText}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <GatsbyImage
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          image={getImage(props.image)}
          alt={props.altText}
        />
      </div>
    </div>
  )
}

export default Hero
