import * as React from "react"
import { ExternalLinkIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const CtaWithImage = props => {
  return (
    <>
      <div className="relative flex flex-col-reverse bg-primaryDarker20">
        <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <GatsbyImage
            className="w-full h-full object-cover"
            image={getImage(props.image)}
            alt={props.altText}
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <h2 className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
              {props.headingText}
            </h2>
            <p className="mt-3 text-lg text-white">{props.paragraphText}</p>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to={props.buttonSlug}
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                >
                  {props.buttonText}
                  <ExternalLinkIcon
                    className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CtaWithImage
