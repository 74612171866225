import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ArrowSmRightIcon } from "@heroicons/react/solid"
import bolt from "../../images/ergsense-bolt.svg"

const HomeBlogContainer = props => {
  const posts = props.posts
  const hideViewAll = !props.hideViewAll
  console.log(props)
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="flex justify-between items-end">
          <div className="flex">
            <h2 className="text-2xl lg:text-3xl mr-2 pt-2 items-center font-extrabold tracking-tight text-gray-900">
              {hideViewAll ? "Featured Posts" : "Latest Posts"}
            </h2>
            <img src={bolt} alt="" width={50} height={50} />
          </div>
          {hideViewAll && (
            <Link to="/blog">
              View all{" "}
              <ArrowSmRightIcon className="h-5 w-5 inline text-primaryDarker20" />
            </Link>
          )}
        </div>
        <div
          className={`mt-4 max-w-lg mx-auto grid gap-5 ${
            hideViewAll ? "lg:grid-cols-2" : "lg:grid-cols-3"
          } lg:max-w-none`}
        >
          {posts.map(post => (
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <a target="_blank" rel="noreferrer" href={post.featuredUrl}>
                  <GatsbyImage
                    image={getImage(post?.featuredPostImage?.localFile)}
                    className="h-48 w-full object-contain"
                    objectFit="contain"
                    alt={post?.featuredPostImage?.altText}
                  />
                </a>
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <div>
                    <div className="inline-block">
                      <span
                        className={`${
                          post.featuredPostCategory === "News"
                            ? "bg-indigo-100 text-indigo-800"
                            : ""
                        } ${
                          post.featuredPostCategory === "Press Release"
                            ? "bg-yellow-100 text-yellow-800"
                            : ""
                        }
                        ${
                          post.featuredPostCategory === "On-Demand Webinar"
                            ? "bg-red-100 text-red-800"
                            : ""
                        }
                        ${
                          post.featuredPostCategory === "Case Study"
                            ? "bg-green-100 text-green-800"
                            : ""
                        } inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium`}
                      >
                        {post.featuredPostCategory}
                      </span>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={post.featuredUrl}
                    className="block mt-2"
                  >
                    <p
                      className="text-xl font-semibold text-primaryDarker20"
                      style={{ minHeight: "3rem" }}
                    >
                      {post.featuredHeading}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.featuredParagraphText}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HomeBlogContainer
